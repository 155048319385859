<script>
import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive/src/VueTextareaAutogrowDirective';
export default {
    name: "ContactForm",
    directives: {
        'autogrow': TextareaAutogrowDirective
    },
components: {},
    props: {},
    data: function () {
        return {
            errors: {},
            errorClasses: 'text-danger text-xs italic',
            labelClasses: 'block uppercase tracking-wide mb-2 text-xs font-bold text-left',
            form: {
                first_name: '',
                last_name: '',
                company: '',
                email: '',
                message: '',
                trap: null // must be empty
            },
            isSent: false,
        };
    },
    methods: {
        hasError(field) {
            return this.errors !== undefined && this.errors[field] !== undefined;
        },
        inputClasses(field) {
            let classes = 'appearance-none block w-full bg-white text-neutral-dark border ' +
                'rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-primary rounded ';
            this.hasError(field) ?
                classes += 'border-danger ' :
                classes += 'border-neutral ';
            return classes;
        },
        async sendForm() {
            if ( this.form.trap !== null ) {
                return;
            }

            let response;
            try {
                response = await this.$http.post('/message', this.form);
                if ( response.status === 200 ) {
                    this.isSent = true;
                    this.emptyForm();
                }
            } catch (e) {
                console.log(e);
            }
        },
        emptyForm() {
            this.form = {};
            this.errors = {};
        }
    },

}
</script>

<template>
    <div>
        <div v-if="!isSent">
            <form class="w-full max-w-xl mx-auto">
                <div class="flex flex-wrap -mx-3 mb-6">

                    <!-- First name -->
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label for="form-first-name" class="focus-within:text-primary">
                            <span :class="labelClasses">Voornaam</span>
                            <input
                                v-model="form.first_name"
                                :class="inputClasses('first_name')"
                                id="form-first-name"
                                type="text"
                            >
                        </label>
                        <p v-if="hasError('first_name')" :class="errorClasses">{{ errors['first_name']}}</p>
                    </div>

                    <!-- Last name -->
                    <div class="w-full md:w-1/2 px-3">
                        <label for="form-last-name" class="focus-within:text-primary">
                            <span :class="labelClasses">Achternaam</span>
                            <input
                                v-model="form.last_name"
                                :class="inputClasses('last_name')"
                                id="form-last-name"
                                type="text"
                            >
                        </label>
                        <p v-if="hasError('last_name')" :class="errorClasses">{{ errors['last_name']}}</p>
                    </div>
                </div>

                <!-- Winnie The Pooh -->
                <div class="hidden">
                    <label for="form-trap">Are you a bot?</label>
                    <input
                        v-model="form.trap"
                        id="form-trap"
                        type="text"
                    >
                </div>

                <!-- Company -->
                <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full px-3">
                        <label for="form-company" class="focus-within:text-primary">
                            <span :class="labelClasses">Bedrijf</span>
                            <input
                                v-model="form.company"
                                :class="inputClasses('company')"
                                id="form-company"
                                type="text"
                            >
                        </label>
                        <p v-if="hasError('company')" :class="errorClasses">{{ errors['company']}}</p>
                    </div>
                </div>

                <!-- E-mail -->
                <div class="flex flex-wrap -mx-3 mb-6">
                    <div class="w-full px-3">
                        <label for="form-email" class="focus-within:text-primary">
                            <span :class="labelClasses">E-mail</span>
                            <input
                                v-model="form.email"
                                :class="inputClasses('email')"
                                id="form-email"
                                type="text"
                            >
                        </label>
                        <p v-if="hasError('email')" :class="errorClasses">{{ errors['email']}}</p>
                    </div>
                </div>

                <!-- Message -->
                <div class="flex flex-wrap -mx-3 mb-2">
                    <div class="w-full px-3">
                        <label for="form-message" class="focus-within:text-primary">
                            <span :class="labelClasses">Bericht</span>
                            <textarea
                                v-model="form.message"
                                :class="inputClasses('message')"
                                class="overflow-hidden"
                                style="resize: none"
                                id="form-message"
                                rows="3"
                                v-autogrow
                            />
                        </label>

                        <p v-if="hasError('message')" :class="errorClasses">{{ errors['message']}}</p>
                    </div>
                </div>

            </form>

            <div class="text-center my-6 mx-auto max-w-xl flex flex-col-reverse md:flex-row justify-between">
                <button
                    @click="emptyForm"
                    class="btn-danger font-bold text-sm sm:text-lg text-center uppercase px-3 py-3 my-3 mx-auto md:mx-0 w-1/2 sm:w-1/4"
                >
                    Wissen
                </button>
                <button
                    @click="sendForm"
                    class="btn-primary font-bold text-sm sm:text-lg text-center uppercase px-3 py-3 my-3 mx-auto md:mx-0 w-1/2 sm:w-1/4"
                >
                    Versturen
                </button>
            </div>
        </div>
        <div v-else class="h-64 pt-12 text-xl">
            Bedankt voor je bericht, we nemen dit zo snel mogelijk op!
        </div>
    </div>
</template>

<style scoped>

</style>
