<script>

export default {
    name: "TextField",
    model: {
        prop: 'content',
        event: 'changed'
    },
    props: {
        content: String,
        edit: Boolean,
        errors: Array,
        iconClass: String,
        isPassword: Boolean,
        noLabel: Boolean,
        placeholder: String,
        showOnlyOnEdit: Boolean
    },
    data() {
        return {
            value: null
        }
    },
    watch: {
        content(newValue, oldValue) {
            this.value = newValue
        },
        value(newValue, oldValue) {
            this.$emit('changed', newValue);
        },
    },
    computed: {
        hasError() {
            return this.errors !== undefined && this.errors.length > 0;
        },
        classes() {
            let classes = '';
            this.iconClass ?
                classes += 'border-b border-l border-t rounded-l ' :
                classes += 'border rounded ';
            this.hasError ?
                classes += 'border-danger ' :
                classes += 'border-neutral ';
            return classes;
        },
    },
    created() {
        this.value = this.content;
    }
}
</script>

<template>
    <div>
        <label
            v-if="!noLabel && ( !showOnlyOnEdit || edit)"
            class="flex flex-row block lowercase tracking-wide text-neutral text-xs font-bold pb-1"
            :for="generateId(placeholder, 'textfield')"
        >
            {{ placeholder }}
        </label>
        <div v-if="edit" class="flex flex-col">
            <div class="flex flex-row">
                <input
                    :id="generateId(placeholder, 'textfield')"
                    v-model="value"
                    :placeholder="placeholder"
                    class="appearance-none block w-full bg-white text-neutral-dark py-3 px-4 leading-tight
                            border-neutral focus:outline-none focus:border-primary text-base"
                    :class="classes"
                    :type="isPassword? 'password' : ''"
                >
                <button
                    v-if="iconClass"
                    @click="$emit('click')"
                    class="h-full py-3 px-4 bg-neutral-light text-neutral-dark border-neutral rounded-r border"
                >
                    <i :class="iconClass"></i>
                </button>
            </div>
            <div v-if="hasError">
                <p v-for="error in errors" class="text-danger text-xs italic">
                    <span v-if="errors.length > 1">•</span> {{ error }}
                </p>
            </div>
        </div>
        <div v-else-if="!showOnlyOnEdit">
            {{ content }}
        </div>
    </div>
</template>
