<script>
export default {
    name: "NavigationBar",
    data: function () {
        return {
            showMenu: false,
        };
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
    },
}
</script>
