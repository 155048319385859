<script>
export default {
    name: "NavigationButton",
    components: {},
    model: {
        prop: 'showMenu',
        event: 'change'
    },
    props: {
        showMenu: Boolean,
    },
    data: function () {
        return {

        };
    },
    computed: {},
    methods: {
        toggleMenu() {
            this.$emit('change', !this.showMenu);
        },
    },
    created() {
    },
    mounted() {
    },

}
</script>

<template>
<button class="flex items-center px-3 py-2 border rounded border-white
               hover:text-green-500 hover:border-white m-auto"
        v-on:click="toggleMenu"
>
    <svg v-if="showMenu" v-cloak class="h-3 w-3" viewBox="0 0 20 20"
         xmlns="http://www.w3.org/2000/svg">
        <line x1="0" y1="0" x2="20" y2="20" style="stroke:rgb(255,0,0);stroke-width:2"/>
        <line x1="0" y1="20" x2="20" y2="0" style="stroke:rgb(255,0,0);stroke-width:2"/>
    </svg>
    <svg v-else class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
    </svg>
</button>
</template>

<style scoped>

</style>
